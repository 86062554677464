import { Dispatch } from 'redux'
import { AggregationPoolEntity, ExecutionLpAEntity, ExecutionLpBEntity, ExecutionLpBRule, ProcessingRuleEntity, ProcessingRuleItem, RoutingRuleEntity } from '../../../entity/configuration'
import { FeedAgregationEntity, FeedPlatformEntity } from '../../../entity/feeding'
import { throwSuccessMessage } from '../../../utils/errors-utils'
import { buildHTTPGetOptions, buildHTTPPostOptions, checkResponse, processError } from '../../../utils/fetch-utils'
import { optionsToStrings, optionsToStringsConditional } from '../../../utils/multiselect-utils'
import { Action } from '../actions'
import { onlyFetchGateways } from '../platforms-actions'

export function changeRoutingRule(rule: any, flag?: boolean) {
  if (flag) {
    rule.changed = false
  } else {
    rule.changed = true
  }
  return { type: Action.ChangeRoutingRule, data: rule }
}

export function changeRoutingRuleNoChange(rule: any) {
  return { type: Action.ChangeRoutingRuleMove, data: rule }
}

export function changeRoutingRuleEffect(rule: any) {
  return { type: Action.ChangeRoutingRule, data: rule }
}

export function changeRoutingRuleMove(rule: any, flag?: boolean) {
  if (flag) {
    rule.changed = false
  } else {
    rule.changed = true
  }
  return { type: Action.ChangeRoutingRuleMove, data: rule }
}

export function changeRoutingRuleImport(rule: RoutingRuleEntity) {
  rule.flagImport = true
  return { type: Action.ChangeRoutingRule, data: rule }
}

export function changeRoutingRuleSelect(rule: RoutingRuleEntity) {
  return { type: Action.ChangeRoutingRule, data: rule }
}

export function addRoutingRule(rule: RoutingRuleEntity, flag?: boolean) {
  rule.changed = true
  return { type: Action.AddRoutingRule, data: { ...rule, flag: flag } }
}

export function addRoutingRuleCopy(rule: RoutingRuleEntity, flag?: boolean) {
  rule.changed = true
  return { type: Action.AddRoutingRule, data: rule }
}

export function addProcessingRule(rule: any) {
  rule.changed = true
  return { type: Action.AddProcessingRule, data: rule }
}

export function changeProcessingRule(rule: any, flag?: boolean) {
  if (flag) {
    rule.changed = false
  } else {
    rule.changed = true
  }
  return { type: Action.ChangeProcessingRule, data: rule }
}
export function changeProcessingRuleImport(rule: any) {
  rule.flagImport = true
  return { type: Action.ChangeProcessingRule, data: rule }
}

export function changeProcessingRuleSelect(rule: any, flag?: boolean) {
  rule.changed = flag ? true : undefined
  return { type: Action.ChangeProcessingRuleSelect, data: rule }
}

export function enableProcessingRule(rule: ProcessingRuleEntity) {
  rule.changed = true
  return { type: Action.EnableProcessingRule, data: rule }
}

export function disableProcessingRule(rule: ProcessingRuleEntity) {
  rule.changed = true
  return { type: Action.DisableProcessingRule, data: rule }
}

export function addAggregationPool(pool: any) {
  pool.changed = true
  return { type: Action.AddAggregationPool, data: pool }
}

export function changeAggregationPool(pool: AggregationPoolEntity, flag?: any) {
  if (flag) {
    pool.changed = false
  } else {
    pool.changed = true
  }
  return { type: Action.ChangeAggregationPool, data: pool }
}

export function changeAggregationPoolImport(pool: any) {
  pool.flagImport = true
  return { type: Action.ChangeAggregationPool, data: pool }
}

export function changeAggregationPoolPreset(pool: AggregationPoolEntity) {
  return { type: Action.ChangeAggregationPool, data: pool }
}

export function changeAggregationPoolOnePreset(pool: AggregationPoolEntity) {
  return { type: Action.ChangeAggregationPool, data: pool }
}

export function changeAggregationPoolSelect(pool: AggregationPoolEntity) {
  return { type: Action.ChangeAggregationPool, data: pool }
}

export function deleteExecPresetOne(data: any) {
  return { type: Action.DeleteExecPresetOne, data }
}

export function deleteExecPreset() {
  return { type: Action.DeleteExecPreset }
}

export function deleteAggregationPool(pool: AggregationPoolEntity) {
  return { type: Action.DeleteAggergationPool, data: pool }
}

export function changeExecutionLp(lp: any, flag?: any) {
  if (flag) {
    lp.changed = false
  } else {
    lp.changed = true
  }
  return { type: Action.ChangeExecutionLp, data: lp }
}

export function changeExecutionLpImport(lp: any) {
  lp.flagImport = true
  return { type: Action.ChangeExecutionLp, data: lp }
}

export function changeExecutionLpOnePreset(lp: any) {
  return { type: Action.ChangeExecutionLp, data: lp }
}

export function enableExecutionLp(lp: ExecutionLpAEntity | ExecutionLpBEntity) {
  lp.changed = true
  return { type: Action.EnableExecutionLp, data: lp }
}

export function disableExecutionLp(lp: ExecutionLpAEntity | ExecutionLpBEntity) {
  lp.changed = true
  return { type: Action.DisableExecutionLp, data: lp }
}

export function addFeedAggregation(aggregation: FeedAgregationEntity) {
  aggregation.changed = true
  return { type: Action.AddFeedAggregation, data: aggregation }
}

export function addFeedAggregationImport(aggregation: FeedAgregationEntity) {
  aggregation.flagImport = true
  return { type: Action.AddFeedAggregation, data: aggregation }
}

export function changeFeedAggregation(aggregation: FeedAgregationEntity, flag?: any) {
  if (flag) {
    aggregation.changed = false
  } else {
    aggregation.changed = true
  }
  return { type: Action.ChangeFeedAggregation, data: aggregation }
}

export function changeFeedAggregationPlat(aggregation: FeedAgregationEntity) {
  return { type: Action.ChangeFeedAggregation, data: aggregation }
}

export function changeFeedAggregationImport(aggregation: FeedAgregationEntity, flag?: boolean) {
  aggregation.flagImport = flag ? false : true
  return { type: Action.ChangeFeedAggregation, data: aggregation }
}

export function changeExecPreset(lp: any) {
  lp.changed = true
  return { type: Action.ChangeExecPreset, data: lp }
}

export function changeFeedAggregationSelect(aggregation: FeedAgregationEntity) {
  return { type: Action.ChangeFeedAggregation, data: aggregation }
}

export function deleteFeedAggregation(aggregation: FeedAgregationEntity) {
  return { type: Action.DeleteFeedAggregation, data: aggregation }
}

export function addFeedPlatform(platform: FeedPlatformEntity) {
  platform.changed = true
  platform.newPlatformFlag = true
  return { type: Action.AddFeedPlatform, data: platform }
}

export function changeFeedPlatform(platform: FeedPlatformEntity) {
  platform.changed = true
  return { type: Action.ChangeFeedPlatform, data: platform }
}

export function changeFeedPlatformImport(platform: FeedPlatformEntity) {
  platform.flagImport = true
  return { type: Action.ChangeFeedPlatform, data: platform }
}

export function changeFeedPlatformSelect(platform: FeedPlatformEntity) {
  platform.changed = true
  return { type: Action.ChangeFeedPlatform, data: platform }
}

export function ChangeFeedPlatformPreset(platform: FeedPlatformEntity) {
  platform.changed = true
  return { type: Action.ChangeFeedPlatformPreset, data: platform }
}

export function deleteFeedPlatform(platform: FeedPlatformEntity) {
  return { type: Action.DeleteFeedPlatform, data: platform }
}

export function executionConfigurationInitial() {
  return { type: Action.ExecutionConfigurationInitial }
}

export function executionConfigurationInitialFind(item: any) {
  return { type: Action.ExecutionConfigurationInitialFind, data: item }
}

export function executionConfigurationChanged() {
  return { type: Action.ExecutionConfigurationChanged }
}

export function highlightRelevant(item: any) {
  return { type: Action.ExecutionHighlightRelevant, data: item }
}

export const executionSelector = (state: any) => state.executionConfiguration

export function fetchExecutionUpdate(arg: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/execution/update', window.location.origin)
    url.searchParams.set('gateway', arg.params.Gateway)

    const { params, body } = arg
    const changableObj = { changed: undefined, highlighted: undefined, activeSwitches: undefined }
    const req: any = {}

    req.RoutingRules = body.routingRules.map((item: RoutingRuleEntity) => ({
      ...item,
      OrderTypes: optionsToStrings(item.OrderTypes),
      Platforms: optionsToStrings(item.Platforms),
      Countries: optionsToStrings(item.Countries),
      Schedule: {
        ...item.Schedule,
        Days: optionsToStrings(item.Schedule.Days),
      },
      ...changableObj,
    }))

    req.ProcessingRules = body.processingRules.map((item: ProcessingRuleEntity) => ({
      ...item,
      Rules: item.Rules.map((rule: ProcessingRuleItem) => ({
        ...rule,

        OrderTypes: optionsToStrings(rule.OrderTypes),
      })),
      ...changableObj,
    }))

    req.AggregationPools = body.aggregationPools.map((item: AggregationPoolEntity) => ({
      ...item,
      Profile: '',
      Lps: optionsToStrings(item.Lps),
      ...changableObj,
    }))

    req.LpsA = body.lps.filter((item: any) => !item.Type.match(/^Bbook$|^BbookHedged$|^VolumeConverter$|^HedgingSyntheticIndex$|^HedgingSyntheticSymbol$/gi)).map((item: any) => ({ ...item, ...changableObj, Profile: '' }))

    req.LpsB = body.lps
      .filter((item: any) => item.Type.match(/^Bbook$|^BbookHedged$|^VolumeConverter$|^HedgingSyntheticIndex$|^HedgingSyntheticSymbol$/gi))
      .map((item: any) => ({
        ...item,
        Rules: item.Rules.map((rule: ExecutionLpBRule) => ({
          ...rule,
          OrderTypes: optionsToStringsConditional(rule.OrderTypes),
        })),
        ...changableObj,
        Profile: '',
      }))

    req.Profiles = body.Profile

    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)
    return fetch(url.toString(), buildHTTPPostOptions(req))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error(`execution.status.${result.Status}`)))
        }
        return onlyFetchExecution(arg.params)
      })
      .then(async (data: any) => {
        const Routing = data.RoutingRules.map((item: any, index: any) => {
          return {
            ...item,
            priority: index,
          }
        })

        const newRoutingRules = await data.ProcessingRules.map((item: any) => {
          return Routing.map((el: any) => {
            if (item.Id === el.ProcessorId) {
              return {
                ...el,
                ProcessorName: item.Name,
              }
            }
          })
        })

        const lpNames = [...data.LpsA, ...data.LpsB].map((item: any) => {
          const arr: any = []
          data.AggregationPools.forEach((el: any) => {
            if (el.Lps.includes(item.Name)) {
              arr.push(el.Profile)
            }
          })
          return {
            ...item,
            bunchProfiles: arr,
          }
        })

        const aggName = data.AggregationPools.map((item: any) => {
          const arr: any = []
          data.ProcessingRules.forEach((el: any) => {
            if (el.LpPool === item.Name) {
              arr.push(el.Profile)
            }
          })

          return {
            ...item,
            bunchProfiles: arr,
          }
        })

        const lpNamesNew = lpNames.map((item: any) => {
          let arr: any = []
          aggName.forEach((el: any) => {
            if (el.Lps.includes(item.Name)) {
              arr.push(...el.bunchProfiles)
            }
          })
          return {
            ...item,
            bunchProfiles: arr.flat(Infinity),
          }
        })
        const profileName = data.Profiles.map((item: any) => item.Name)
        const routing = newRoutingRules
          .flat(Infinity)
          .filter(Boolean)
          .sort((a: any, b: any) => a.priority - b.priority)

        const funcSort = (profileName: any, arr: any) => {
          const newArr = []
          for (let i = 0; i < profileName.length; i++) {
            for (let j = 0; j < arr.length; j++) {
              if (profileName[i] === arr[j].Profile) {
                newArr.push(arr[j])
              }
            }
          }
          return newArr
        }

        dispatch({
          type: Action.GotRoutingRules,
          data: funcSort(profileName, routing),
        })
        dispatch({
          type: Action.GotProcessingRules,
          data: funcSort(profileName, data.ProcessingRules),
        })
        dispatch({
          type: Action.GotAggregationPools,
          data: aggName,
        })
        dispatch({
          type: Action.GotExecutionLP,
          data: lpNamesNew,
        })
        dispatch(executionConfigurationInitial())
        throwSuccessMessage('Successfully')
        return onlyFetchGateways()
      })
      .then((response: Response) => checkResponse(response))
      .then(gates => dispatch({ type: Action.GotGateways, data: gates }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

function onlyFetchExecution(params: any) {
  const url = new URL('/api/execution', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)

  return fetch(url.toString(), buildHTTPGetOptions()).then((response: Response) => checkResponse(response))
}

export function fetchExecution(params: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return onlyFetchExecution(params)
      .then(async (data: any) => {
        const Routing = data.RoutingRules.map((item: any, index: any) => {
          return {
            ...item,
            priority: index,
          }
        })

        const newRoutingRules = await data.ProcessingRules.map((item: any) => {
          return Routing.map((el: any) => {
            if (item.Id === el.ProcessorId) {
              return {
                ...el,
                ProcessorName: item.Name,
              }
            }
          })
        })

        const lpNames = [...data.LpsA, ...data.LpsB].map((item: any) => {
          const arr: any = []
          data.AggregationPools.forEach((el: any) => {
            if (el.Lps.includes(item.Name)) {
              arr.push(el.Profile)
            }
          })
          return {
            ...item,
            bunchProfiles: arr,
          }
        })

        const aggName = data.AggregationPools.map((item: any) => {
          const arr: any = []
          data.ProcessingRules.forEach((el: any) => {
            if (el.LpPool === item.Name) {
              arr.push(el.Profile)
            }
          })

          return {
            ...item,
            bunchProfiles: arr,
          }
        })

        const lpNamesNew = lpNames.map((item: any) => {
          let arr: any = []
          aggName.forEach((el: any) => {
            if (el.Lps.includes(item.Name)) {
              arr.push(...el.bunchProfiles)
            }
          })
          return {
            ...item,
            bunchProfiles: arr.flat(Infinity),
          }
        })

        const profileName = data.Profiles.map((item: any) => item.Name)
        const routing = newRoutingRules
          .flat(Infinity)
          .filter(Boolean)
          .sort((a: any, b: any) => a.priority - b.priority)

        const funcSort = (profileName: any, arr: any) => {
          const newArr = []
          for (let i = 0; i < profileName.length; i++) {
            for (let j = 0; j < arr.length; j++) {
              if (profileName[i] === arr[j].Profile) {
                newArr.push(arr[j])
              }
            }
          }
          return newArr
        }
        dispatch({
          type: Action.GotRoutingRules,
          data: funcSort(profileName, routing),
        })
        dispatch({
          type: Action.GotProcessingRules,
          data: funcSort(profileName, data.ProcessingRules),
        })
        dispatch({
          type: Action.GotAggregationPools,
          data: aggName,
        })
        dispatch({
          type: Action.GotExecutionLP,
          data: lpNamesNew,
        })

        dispatch({
          type: Action.GotProfile,
          data: data.Profiles,
        })

        dispatch(executionConfigurationInitial())
      })
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

//==========
export function onlyFetchExecutionPool(params: any) {
  const url = new URL('/api/execution/getbbookhedgedpools', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)

  return fetch(url.toString(), buildHTTPGetOptions()).then((response: Response) => checkResponse(response))
}

export function onlyFetchExecutionPoolVolume(params: any) {
  const url = new URL('/api/execution/GetVolumeConverterPools', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)

  return fetch(url.toString(), buildHTTPGetOptions()).then((response: Response) => checkResponse(response))
}

export function fetchExecutionPoolVolume(params: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return onlyFetchExecutionPoolVolume(params)
      .then((data: any) => {
        dispatch({ type: Action.GotExecPoolVolume, data: data })
      })
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export function onlyFetchCountries(params: any) {
  const url = new URL('api/countries', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)

  return fetch(url.toString(), buildHTTPGetOptions()).then((response: Response) => checkResponse(response))
}

export function fetchCountries(params: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return onlyFetchCountries(params)
      .then((data: any) => {
        dispatch({ type: Action.GotCountries, data: data })
      })
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export function fetchExecutionPool(params: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return onlyFetchExecutionPool(params)
      .then((data: any) => {
        dispatch({ type: Action.GotExecPool, data: data })
      })
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}
//==========

export function getProcessingRuleItems(data: any) {
  return { type: Action.GotProcessingRuleItems, data }
}

export function addProcessingRuleItem(data: any) {
  return { type: Action.AddProcessingRuleItem, data }
}

export function addProcessingRuleItems(data: any) {
  return { type: Action.AddProcessingRuleItems, data }
}

export function changeProcessingRuleItem(data: any) {
  return { type: Action.ChangeProcessingRuleItem, data }
}

export function changeProcessing(data: any) {
  return { type: Action.ChangeProcessing, data }
}

export function deleteProcessingRuleItem(data: any) {
  return { type: Action.DeleteProcessingRuleItem, data }
}

export function deleteProcessingRuleItems(data: any) {
  return { type: Action.DeleteProcessingRuleItems, data }
}

export function changeProcessingRuleItemPriority(data: any) {
  return { type: Action.ChangeProcessingRuleItemPriority, data }
}

export function getExecutionLpRules(data: any) {
  return { type: Action.GotExecutionLpBRules, data }
}

export function addExecutionLpRule(data: any) {
  return { type: Action.AddExecutionLpBRule, data }
}

export function changeExecutionLpRule(data: { rule: ExecutionLpBRule; params: { Lp: string } }) {
  return { type: Action.ChangeExecutionLpBRule, data }
}

export function deleteExecutionLpRule(data: any) {
  return { type: Action.DeleteExecutionLpBRule, data }
}

export function deleteExecutionLpRules(data: any) {
  return { type: Action.DeleteExecutionLpBRules, data }
}

export function changeExecutionLpRulePriority(data: any) {
  return { type: Action.ChangeExecutionLpBRulePriority, data }
}
